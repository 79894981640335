import { useTranslations } from 'next-intl'

import { DeliveryDateData } from '@/api'
import { Currency } from '@/components/currency'
import { useCartContext, useStoreContext } from '@/providers'

interface Props {
  deliveryDateData: DeliveryDateData
  productPrice: number | null | undefined
}

export const DeliveryInfo = ({ deliveryDateData, productPrice }: Props) => {
  const t = useTranslations('Product')
  const { cartItems, isCartRefetching } = useCartContext()
  const {
    storeConfig: { currency },
  } = useStoreContext()

  const deliveryPrice = deliveryDateData?.methods.length ? Math.min(
    ...deliveryDateData?.methods
      .filter(item => item.price !== 0)
      .map(item => item.price)
  ) : null;

  const deliveryFreeShippingThreshold = deliveryDateData?.methods.length ?  Math.min(
    ...deliveryDateData?.methods
      .filter(item => item.free_shipping_threshold !== null)
      .map(item => item.free_shipping_threshold)
  ) : null;

  const totalSum = cartItems.reduce((acc, item) => {
    return acc + (item?.prices?.row_total_including_tax?.value || 0)
  }, 0)

  if (deliveryPrice === null || deliveryFreeShippingThreshold === null || isCartRefetching) {
    return null;
  }

  const isDeliveryFree =
    totalSum >= deliveryFreeShippingThreshold || productPrice && productPrice >= deliveryFreeShippingThreshold

  return isDeliveryFree ? (
    t('detail.price.delivery.free')
  ) : (
    <>
      <div>
        <span>{t('detail.price.delivery.from')}&nbsp;</span>
        <Currency currency={currency} price={deliveryPrice} />
      </div>
      <span className="hidden md:inline border-l border-grey-300 w-[1px] h-5 mx-2" />
      <div>
        <span>{t('detail.price.delivery.freeFrom')}&nbsp;</span>
        <Currency
          currency={currency}
          price={deliveryFreeShippingThreshold}
        />
      </div>
    </>
  )
}
