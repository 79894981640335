'use client'
import { useTranslations } from 'next-intl'
import { twJoin, twMerge } from 'tailwind-merge'

import { DeliveryDateData } from '@/api'
import { Currency } from '@/components/currency'
import { QuestionMarkRound } from '@/components/icons/svg'
import { Tooltip } from '@/components/tooltip'
import { useAuthContext } from '@/providers'
import { obsoloteGetPrice } from '@/utils'
import { DeliveryInfo } from './delivery-info'
import { UnitPrice } from './unit-price'
import { selectors } from '@/common/constants/selectors-constants'
import { ProductPriceInputData } from '@/common/utils/obsolete-get-price/obsolete-get-price.types'

type PriceProps = {
  inStock: boolean
  productPrice: ProductPriceInputData
  deliveryDateData?: DeliveryDateData
  small?: boolean
  unitPriceVisible?: boolean
  unitPrice?: {
    value: number
    unit: string
  }
}

export function Price({
  deliveryDateData,
  inStock,
  unitPrice,
  productPrice,
  small,
  unitPriceVisible,
}: PriceProps): JSX.Element | null {
  const t = useTranslations('Product')
  const { customerData } = useAuthContext()
  const {
    showRegularPrice,
    recommendedPrice,
    finalPrice,
    wholesaleDiscountPercentageFormatted,
    wholesaleDiscountPercentage,
  } = obsoloteGetPrice(productPrice)

  const wholesaleDiscount = t.rich('detail.price.discount.wholesaleDiscount', {
    percent: () =>
      `${
        wholesaleDiscountPercentage ? '-' : ''
      }${wholesaleDiscountPercentageFormatted}`,
  })

  const isB2B = !!customerData?.is_b2b

  if (inStock) {
    return (
      <>
        <div data-test={selectors.PDP.productPrice} className="mt-4">
          <Currency
            className={twJoin(
              'text-secondary',
              small ? 'text-[15px]' : 'text-lg',
              'font-bold',
            )}
            currency={finalPrice?.currency}
            price={finalPrice?.value}
          />
        </div>
        {showRegularPrice && (
          <div className="text-grey-300 text-xs flex items-center">
            <Currency
              className="line-through"
              currency={recommendedPrice?.currency}
              price={recommendedPrice?.value}
            />
            <span className="cursor-help inline-flex ml-1">
              <Tooltip
                direction="right"
                text={
                  <span className="w-[210px] inline-block text-tooltip-text">
                    {isB2B
                      ? t('detail.price.discount.recommendedRetailPrice')
                      : t('detail.price.lowestLastPrice')}
                  </span>
                }
              >
                <QuestionMarkRound
                  className="fill-grey-300"
                  width={18}
                  height={18}
                />
              </Tooltip>
            </span>
          </div>
        )}
        {!!unitPriceVisible && unitPrice && (
          <UnitPrice unitPrice={unitPrice} currency={finalPrice?.currency} />
        )}
        {isB2B && showRegularPrice && (
          <div className="py-2">{wholesaleDiscount}</div>
        )}
        {!isB2B && deliveryDateData && deliveryDateData.methods.length > 0 && (
          <div
            className={twJoin(
              'flex flex-col md:flex-row',
              'md:items-start',
              'text-grey-300',
            )}
          >
            <DeliveryInfo
              deliveryDateData={deliveryDateData}
              productPrice={finalPrice?.value}
            />
          </div>
        )}
      </>
    )
  } else if (finalPrice) {
    return (
      <div className="mt-2 price-estimated">
        <span className={twMerge('label', 'text-lg font-bold')}>
          {t('detail.price.expectedPrice')}&nbsp;
        </span>
        <Currency
          className="text-secondary text-lg font-bold"
          currency={finalPrice?.currency}
          price={finalPrice?.value}
        />
      </div>
    )
  } else {
    return null
  }
}
