import {
  ConfigurableProductOptionFragment,
  ConfigurableProductVariantFragment,
  ConfigurableProductVariantAttributeFragment,
} from '@/api'
import { obsoletefilterConfigurableVariants } from './obsolete-filter-configurable-variants'
import { obsoleteGetConfigurableVariantAttributes } from './obsolete-get-configurable-variant-attributes'

export interface ObsoleteGetConfigurableVariantAttributesRecordProps {
  options: ConfigurableProductOptionFragment[]
  variants: ConfigurableProductVariantFragment[]
  selectedVariant: ConfigurableProductVariantFragment | undefined
}
export const obsoleteGetConfigurableVariantAttributesRecord = ({
  options,
  variants,
  selectedVariant,
}: ObsoleteGetConfigurableVariantAttributesRecordProps): Record<
  string,
  ConfigurableProductVariantAttributeFragment[]
> => {
  if (!selectedVariant) {
    return {}
  }

  // after each option, we have to filter out variants,
  // that do not have attribute.value_index from selected configurableProductVariant
  let filteredVariants = variants

  return options?.reduce<
    Record<string, ConfigurableProductVariantAttributeFragment[]>
  >((accumulator, option) => {
    const { attribute_code: attributeCode } = option

    // add variant attributes for specific attribute code
    if (attributeCode) {
      accumulator[attributeCode] = obsoleteGetConfigurableVariantAttributes({
        variants: filteredVariants,
        variantAttributeCode: attributeCode,
      })
    }

    // get attribute for current option
    const selectedVariantAttribute = selectedVariant?.attributes?.find(
      (attribute) => attribute?.code === attributeCode,
    )

    if (selectedVariantAttribute) {
      filteredVariants = obsoletefilterConfigurableVariants({
        variants: filteredVariants,
        attribute: selectedVariantAttribute,
      })
    }

    return accumulator
  }, {})
}
