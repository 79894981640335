import {
  ConfigurableProductVariantFragment,
  ConfigurableProductVariantAttributeFragment,
} from '@/api'

export interface ObsoleteGetConfigurableVariantAttributesProps {
  variants: ConfigurableProductVariantFragment[]
  variantAttributeCode: string
}

/**
 * Get all the product variant attributes from product variants that have product variant attribute code
 * one distinct attribute has specific code AND value
 *
 * @param variants
 * @param variantAttributeCode
 */
export const obsoleteGetConfigurableVariantAttributes = ({
  variants,
  variantAttributeCode,
}: ObsoleteGetConfigurableVariantAttributesProps): ConfigurableProductVariantAttributeFragment[] => {
  return variants.reduce<ConfigurableProductVariantAttributeFragment[]>(
    (accumulator, variant, key) => {
      const correctAttribute = variant?.attributes?.find(
        (attribute) => attribute?.code === variantAttributeCode,
      )

      if (
        correctAttribute &&
        !accumulator.find(
          (ca) => ca.value_index === correctAttribute.value_index,
        )
      ) {
        accumulator.push(correctAttribute)
      }

      return accumulator
    },
    [],
  )
}
