import {
  ConfigurableProductVariantAttributeFragment,
  ConfigurableProductVariantFragment,
} from '@/api'

export interface ObosleteFilterConfigurableVariantsProps {
  variants: ConfigurableProductVariantFragment[]
  attribute: ConfigurableProductVariantAttributeFragment
}

/**
 * Filter product variants based on product variant attribute
 *
 * @param variants - array of variants that needs to be filtered
 * @param attribute - attribute that each variant has to have
 */
export const obsoletefilterConfigurableVariants = ({
  variants,
  attribute,
}: ObosleteFilterConfigurableVariantsProps): ConfigurableProductVariantFragment[] => {
  const { code: attributeCode, value_index: attributeValue } = attribute

  return variants.filter((variant) => {
    const { attributes } = variant

    // select correct attribute based on code
    const correctAttribute = attributes?.find(
      (attribute) => attribute?.code === attributeCode,
    )

    return !!correctAttribute && correctAttribute.value_index === attributeValue
  })
}
