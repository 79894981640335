'use client'

import { useTranslations } from 'next-intl'
import { twMerge } from 'tailwind-merge'

import { StockSubscribeForm } from '@/components/stock-subscribe-form'
import { useObsoloteProductDataContext } from '@/providers'
import { useIsBrowserMobile } from '@/common/hooks/use-is-browser-resolution'

export const ProductDetailStockSubscribe = () => {
  const t = useTranslations('Product')
  const { configurableProductVariant } = useObsoloteProductDataContext()
  const isBrowserMobile = useIsBrowserMobile()

  const productId = configurableProductVariant?.product?.id

  return productId ? (
    <div className="mb-5">
      <p className="my-5 block font-normal">{t('detail.notification.text')}</p>
      <div className="notification-container inline-flex flex-col items-baseline md:w-8/12 w-full">
        <StockSubscribeForm
          productId={productId}
          buttonLabel={t('detail.notification.button')}
          wrapperClasses="justify-start"
          buttonClasses={twMerge('h-12 px-4 mt-6', isBrowserMobile && 'w-full')}
        />
      </div>
    </div>
  ) : null
}
