import './configurable-variant-component.css'

import { ChangeEvent, useCallback, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ConfigurableProductOptionFragment,
  ConfigurableProductVariantAttributeFragment,
  ConfigurableProductVariantFragment,
} from '@/api'
import { Select } from '../select'
import { Field } from '../field'

type Props = {
  disabled: boolean
  option: ConfigurableProductOptionFragment
  configurableProductVariant: ConfigurableProductVariantFragment | undefined
  className?: string
  variantAttributesRecord: Record<
    string,
    ConfigurableProductVariantAttributeFragment[]
  >
  handleAttributeChangeForVariant: (
    selectedAttributeValueIndex: number,
    selectedAttributeCode: string,
    attributes: ConfigurableProductVariantAttributeFragment[],
  ) => void
}

export function ConfigurableVariant({
  disabled,
  option,
  configurableProductVariant,
  variantAttributesRecord,
  handleAttributeChangeForVariant,
  className,
}: Props): JSX.Element {
  const attributeCode = option.attribute_code ?? ''

  const variantAttributes = useMemo(
    () => variantAttributesRecord[attributeCode] ?? [],
    [variantAttributesRecord, attributeCode],
  )
  const selectedProductValueIndex = useMemo(
    () =>
      variantAttributes?.find((variant) =>
        configurableProductVariant?.attributes?.some(
          (attribute) => attribute?.value_index === variant?.value_index,
        ),
      )?.value_index ?? undefined,
    [configurableProductVariant?.attributes, variantAttributes],
  )

  const handleSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const selectedAttributeValueIndex = Number(e.target.value)

      handleAttributeChangeForVariant(
        selectedAttributeValueIndex,
        attributeCode,
        variantAttributes,
      )
    },
    [attributeCode, handleAttributeChangeForVariant, variantAttributes],
  )

  return (
    <div
      key={option.attribute_code}
      className={twMerge('md:mb-0 mb-5 text-left mr-2', className)}
    >
      <Field required label={option.label}>
        <Select
          onChange={handleSelectChange}
          value={selectedProductValueIndex}
          className="w-full text-sm min-h-[28px] mt-1 appearance-none bg-white bg-no-repeat pr-[2em] variants-dropdown"
          aria-label={option.label ?? ''}
          disabled={disabled}
        >
          {variantAttributes.map((variant) => (
            <option
              key={variant.value_index}
              value={variant.value_index ?? undefined}
            >
              {variant.label}
            </option>
          ))}
        </Select>
      </Field>
    </div>
  )
}
