'use client'

import { useTranslations } from 'next-intl'

import { useCartContext } from '@/providers'
import { IconButton } from '../icon-button'
import { CartIcon } from '../icons/svg'
import { selectors } from '@/common/constants/selectors-constants'

interface ProductDetailVariantsAddToCartButtonProps {
  buttonText: string
  className?: string
  addToCart?: () => void
}

export const ProductDetailVariantsAddToCartButton = ({
  buttonText,
  className,
  addToCart,
}: ProductDetailVariantsAddToCartButtonProps) => {
  const t = useTranslations('Product')
  const { isCartRefetching } = useCartContext()
  const disabled = isCartRefetching || !addToCart
  const classes = `h-[50px] w-full md:w-[210px] lg:w-[280px] ${className ?? ''}`

  return (
    <IconButton
      title={buttonText}
      aria-label={buttonText}
      variant="secondary"
      className={classes}
      disabled={disabled}
      onClick={addToCart}
      data-test={selectors.PDP.addToCartMain}
    >
      <div className="flex bold text-sm text-white uppercase gap-4">
        <div className="my-auto" data-test={selectors.common.autocomplete.myAuto}>{buttonText}</div>
        <CartIcon fill="#ffffff" width="30px" height="30px" />
      </div>
    </IconButton>
  )
}
