import {
  ConfigurableProductVariantAttributeFragment,
  ConfigurableProductVariantFragment,
} from '@/api'

export const obsoleteGetHandleAttributeChange =
  (
    variant: ConfigurableProductVariantFragment | undefined,
    variants: ConfigurableProductVariantFragment[],
    setVariant: (variant: ConfigurableProductVariantFragment) => void,
  ) =>
  (
    selectedAttributeValueIndex: number,
    selectedAttributeCode: string,
    attributes: ConfigurableProductVariantAttributeFragment[],
  ) => {
    const selectedAttribute = attributes.find(
      (attribute) => attribute.value_index === selectedAttributeValueIndex,
    )
    const newVariantAttributes = [
      ...(variant?.attributes?.filter(
        (attribute) => attribute?.code !== selectedAttributeCode,
      ) ?? []),
      selectedAttribute,
    ]

    const foundVariant = variants.find((variant) =>
      variant?.attributes?.every((attribute) => {
        const newVariantAttribute = newVariantAttributes.find(
          (newAttribute) => newAttribute?.code === attribute?.code,
        )
        return newVariantAttribute?.value_index === attribute?.value_index
      }),
    )

    if (foundVariant) {
      setVariant(foundVariant)
      return
    }

    const firstPossibleVariant = variants.find((variant) =>
      variant?.attributes?.find(
        (attribute) =>
          attribute?.code === selectedAttributeCode &&
          attribute?.value_index === selectedAttributeValueIndex,
      ),
    )
    if (firstPossibleVariant) {
      setVariant(firstPossibleVariant)
    }
  }
